<template>
	<div>
		<div class="xieyi">
			<div></div>
			<div>
				<div>
					<div>
						
					</div>
					<div class="hezuo">
						<div>入学签订就业协议</div>
						<div> </div>
					</div>
				</div>
			</div>
			<div></div>
			<div>
				<div>
					<div>
						
					</div>
					<div class="hezuo">
						<div>一对一就业辅导</div>
						<div>大厂技术模拟面试</div>
					</div>
				</div>
			</div>
			<div></div>
			<div>
				<div>
					<div>
						
					</div>
					<div class="hezuo">
						<div>与企业合作</div>
						<div>就业推荐通道</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		
	}
</script>

<style scoped>
	.xieyi{
		width: 100rem;
		height: auto;
		box-sizing: border-box; 
		margin: 0 auto;
		display: flex;
		justify-content:space-between;
		flex-wrap: wrap;
		background-color: #FFFFFF;
	}
	.xieyi >div{
		box-sizing: border-box;
		width: 33%;
		height: auto;
	}
	.xieyi >div:nth-child(1){
		background: url(../../assets/images/sy/ss1.png);
		background-size: 100%;
		background-repeat: no-repeat;
		
	}
	.xieyi >div:nth-child(3){
		background: url(../../assets/images/sy/ss2.png);
		background-size: 100%;
		background-repeat: no-repeat;
		
	}
	.xieyi >div:nth-child(5){
		background: url(../../assets/images/sy/ss3.png);
		background-size: 100%;
		background-repeat: no-repeat;
		
	}
	.xieyi >div:nth-child(2),.xieyi >div:nth-child(4),.xieyi >div:nth-child(6){
		background-color: #f5f6fa; 
	}
	.xieyi >div:nth-child(2) >div{
		width: 9rem;
		height: 7.4375rem;
		/* border: 1px solid red; */
		background: url(../../assets/images/sy/01.png);
		background-size: 9rem 7.4375rem;
		background-repeat: no-repeat;
		margin: 6rem auto;
	}
	.xieyi >div:nth-child(4) >div{
		width: 9rem;
		height: 7.4375rem;
		/* border: 1px solid red; */
		background: url(../../assets/images/sy/02.png);
		background-size: 9rem 7.4375rem;
		background-repeat: no-repeat;
		margin: 6rem auto;
	}
	.xieyi >div:nth-child(6) >div{
		width: 9rem;
		height: 7.4375rem;
		/* border: 1px solid red; */
		background: url(../../assets/images/sy/03.png);
		background-size: 9rem 7.4375rem;
		background-repeat: no-repeat;
		margin: 6rem auto;
	}
	.xieyi >div:nth-child(2),.xieyi >div:nth-child(4),.xieyi >div:nth-child(6) >div:nth-child(1){ 
		text-align: center;
		line-height: 5rem;
		font-family: MicrosoftYaHei;
			font-size: 1.875rem; 
			font-weight: normal;
			font-stretch: normal; 
			letter-spacing: 0px;
			color: #000004;
	}
	.hezuo{
		margin-left: -50%;
		width: 18.75rem;
		height: 6.25rem;
		/* border: 1px solid red; */
	}
	.hezuo > div{
		/* font-size: 1rem; */
	}
	
</style>

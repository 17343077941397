<template>
  <div class="enterprise_con enterprise-box">
    <ul>
      <li v-for="(i,index) in company" :key="index">
        <img :src="i.image" :alt="i.title" />
      </li>
    </ul>
  </div>
</template>
<script>
const axios = require("axios").default;
export default {
  name: "enterprise",
  props: {
    num: { type: Number, required: true },
    limit: { type: Number, required: true }
  },
  data() {
    return {
      company: []
    };
  },
  methods: {
    getCompany() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("合作企业") +
            "&limit=" +
            this.limit
        )
        .then(({ data: { data = [] } }) => {
          this.company = data;
        });
    }
  },
  mounted() {
    this.getCompany();
  }
};
</script>
<style>
/*高薪就业*/
.cooperation {
  width: 62.5vw !important;
  margin: 0 auto !important;
}

.cooperation ul li {
  margin-left: 0.466vw;
  width: 10.031vw;
  height: 3.818vw;
  margin-bottom: 0.5vw;
  padding: 0.4vw 1vw;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*首页*/
.enterprise_con ul li {
  width: 7.916vw;
  height: 2.916vw;
  margin-bottom: 0.5vw;
  border: 1px solid rgba(191, 191, 191, 1);
  margin-left: 0.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.enterprise_con ul li img,
.cooperation ul li img {
  width: 65% !important;
  height: 65% !important;
}
.enterprise_con ul li img:hover,
.cooperation ul li img:hover {
  transform: translateY(-6px);
  transition: all 0.5s;
}
/*公共*/
.enterprise-box ul {
  overflow: hidden;
  margin-bottom: 16px;
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20*/
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.enterprise-box ul li img {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div>
    <div class="width-100 ten-box">
      <div class="tenBj-box width-100">
        <div class="ten-con width-66 flex_style justify_center">
          <div class="tenMiddle">
            <div class="bgOne">
              <img src="../../assets/images/q1_y.png" alt="图片" />
            </div>
            <div class="bgTow">
              <img src="../../assets/images/q2_y.png" alt="图片" />
            </div>
            <div class="bgThree">
              <img src="../../assets/images/q3_y.png" alt="图片" />
            </div>
            <div class="bgFore flex_style align_center justify_center">
              <img src="../../assets/images/q4_y.png" alt="图片" />
            </div>
            <div class="middleText flex_style align_center justify_center">
              <p>课程体系</p>
            </div>
          </div>
          <div class="bgFive">
            <img src="../../assets/images/zx_jxtx.png" alt="图片" />
          </div>
          <div
            class="system1 flex_style direction_column justify_between align_flex-start"
          >
            <p>听课测评</p>
            <p>周考、月考、月度降班</p>
            <p>适度淘汰</p>
          </div>
          <div
            class="system2 flex_style direction_column justify_between align_flex-start"
          >
            <p>教学PDCA循环</p>
            <p>游戏化教学</p>
            <p>KPI考核</p>
          </div>
          <div
            class="system3 flex_style direction_column justify_between align_flex-start"
          >
            <p>自主学习</p>
            <p>积分激励</p>
            <p>方法为主</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textList: [
        { title: "小班教学", text: "所有学员雨露均沾 教学效果质量优" },
        {
          title: "早晚自习全程陪同",
          text: "督促学生集中精力学习 提高学习效率",
        },
        { title: "24小时群内答疑", text: "学员可随时在线提问 讲师及时解答" },
        { title: "落后学员帮扶", text: "所有学员一视同仁 不落下一名学生" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.tenBj-box {
  height: auto;
  background: url("../../assets/images/bj_jxtx.jpg") no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}
.title-box p,
.title-box h1 {
  color: #ffffff !important;
}
.ten-con {
  margin-top: 3.8vw;
  position: relative;
}
.tenMiddle {
  position: relative;
}
.bgOne {
  -webkit-animation: myfirst1 6s linear infinite;
}
.bgOne,
.bgOne img {
  width: 35.97vw;
  height: 35.97vw;
  border-radius: 50%;
}
.bgTow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: myfirst2 5s linear infinite;
}
.bgTow,
.bgTow img {
  width: 30.55vw;
  height: 30.55vw;
  z-index: 9;
}
.bgThree {
  -webkit-animation: myfirst3 4s linear infinite;
}
.bgThree,
.bgThree img {
  width: 18vw;
  height: 18vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.bgFore img {
  width: 13vw;
  height: 13vw;
}
.bgFore {
  width: 13vw;
  height: 13vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  -webkit-animation: myfirst4 3s linear infinite;
}
.middleText {
  width: 6vw;
  height: 6vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.middleText p {
  width: 6vw;
  font-size: 2.6rem;
  line-height: 2.6rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgb(0, 218, 255);
  z-index: 9;
}

.bgFive,
.bgFive img {
  width: 41vw;
  height: 41vw;
  position: absolute;
  right: 5.4vw;
  top: -1.3vw;
  z-index: 9;
}
.system1 {
  width: 13.58vw;
  height: 6.28vw;
  background: url("../../assets/images/k2_zlkz.png") no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  padding: 0.8vw;
  text-align: justify;
  animation: sc linear infinite 4s;
}
.system1 p,
.system2 p,
.system3 {
  display: inline-block;
  width: 100%;
  font-size: 1.6rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.6rem;
}
.system1 p:nth-child(2),
.system2 p:first-child {
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}
.system2 {
  width: 9.6vw;
  height: 5.8vw;
  background: url("../../assets/images/k1_jsjx.png") no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 9;
  padding: 0.8vw;
  text-align: justify;
  animation: sc linear infinite 4s;
}
.system3 {
  width: 6vw !important;
  height: 7vw !important;
  background: url("../../assets/images/k3_xsxx.png") no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  right: 4vw !important;
  bottom: 4vw !important;
  z-index: 9;
  text-align: justify;
  padding: 1vw;
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
  animation: sc linear infinite 4s;
}
.system3 p {
  text-align-last: justify;
  text-align: justify;
  text-justify: distribute-all-lines; // 这行必加，兼容ie浏览器
}
.bottomLi,
.bottomLi ul {
  height: 13.05vw;
  background: url("../../assets/images/hd_jxtx.jpg") no-repeat;
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}
.bottomLi ul li {
  width: 14.45vw;
  height: 100%;
  padding: 0 1vw;
}
.bottomLi ul li h3 {
  margin: 2.5vw 0 1.2vw;
}
.bottomLi ul li h3,
.bottomLi ul li p {
  font-size: 2.16rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: #364656;
}
.bottomLi ul li p {
  font-size: 1.55rem !important;
  font-weight: 400 !important;
  color: #4a5c74 !important;
  text-align: justify;
  line-height: 3rem;
}
@-webkit-keyframes myfirst1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes myfirst2 {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes myfirst3 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes myfirst4 {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes sc {
  0% {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
</style>

<template>
    <div v-if='videoState' >
        <div class='maska' @click='masksCloseFun()'></div>
        <div class="videomasks">
            <video :src='videoSrc' controls='controls' autoplay style="width: 57vw;height:32vw;">
                您的浏览器不支持 video 标签。
            </video>
        </div>
    </div>
	<div v-else-if='imgState' >
		<div class='maska' @click='masksCloseFun()'></div>
		<div class="imagemasks">
		  <img :src='imgSrc' controls='controls' autoplay  class="show-img" />
		</div>
	</div>
</template>

<script>
    export default {
        name: 'video_masks',
        data() {
            return {
                videoState: false,
                videoSrc: '',
				imgSrc: '',
				imgState: false
            }
        },
        methods: {
            // banner播放
            checkVideoFun(src) {
                this.videoState = true;
                this.videoSrc = src;
            },
			checkimgFun(src) {
			    this.imgState = true;
			    this.imgSrc = src;
			},
            masksCloseFun() {
                this.videoState = false;
				this.imgState = false;
            },
        }
    }
</script>
<style lang="scss" scoped>
    .maska {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: #000000;
        opacity: .6;
    }

    .videomasks {
        max-width: 62vw;
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: 20;
        transform: translate(-50%, -50%);
    }

    .videomasks video {
        width: 100%;
        height: 100%;
    }
	.imagemasks{
	    max-width: 1000px;
	    position: fixed;
	    left: 50%;
	    top: 58%;
		overflow: auto;
		height:550px;
	    z-index: 9999999;
	    transform: translate(-50%,-50%);
	  }
	  .imagemasks::-webkit-scrollbar{
	      display: none;
	  }
	  .imagemasks img{
	    width: 100%;
	  }
</style>

<template>
  <div class="mask" v-show="isShow">
    <div class="popup_box" @click="leave()">
      <div class="btn-zixun btns" @click="advice"></div>
    </div>
  </div>
</template>

<script>
const axios = require("axios").default;
export default {
  name: "popup",
  data() {
    return {
      isShow: true,
    };
  },
  methods: {
    advice(){
      window.mantis.requestChat()
    },
    leave() {
      this.isShow = false;
    },
  },
};
</script>

<style scoped>
.mask {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
}
.popup_box {
  width: 40vw;
  height: 42vw;
  background: url("../../assets/images/tcdbj.png") no-repeat;
  background-size: auto 100%;
  position: relative;
  z-index: 1000000;
  cursor: pointer;
  animation: appear 1.5s ease-in-out 1 alternate forwards;
}
@keyframes appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  width: 3.08vw;
  height: 3.08vw;
  position: absolute;
  right: 2.4vw;
  top: 8.2vw;
}
.btn-zixun {
  position: absolute;
  bottom: 6.5vw;
  left: 12.22vw;
  width: 15vw;
  height: 3.08vw;
  z-index: 10000;
}
</style>

<template>
	<div class="money_box">

		<div class="money_list"> 
				<div class="money_text_item">
					<img src="../../assets/images/2020.png" >
				</div>
				<div class="money_text_item">
					<img src="../../assets/images/2021.png" >
				</div>
				<div class="money_text_item">
					<img src="../../assets/images/2022.png" >
				</div>
				<div class="money_text_item">
					<img src="../../assets/images/2023.png" >
				</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style>
	.money_box {
		width: 100%;
		height: auto;
		background-color: #f4f4f4;
	}

	#o1 {
		background-color: #F5F5F5;
	}

	.money_list {
		width: 1200px;
		height: 420px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.money_list_img {
		width: 40px;
		height: 40px;
		margin-left: 28px;
		margin-top: 38px;
		margin-bottom: 18px;
	}



	.money_text {
		width: 284px;
		height: 630px;
		background-color: #54d4cf;
		text-align: left;
		margin-right: -22px;
		border-radius: 10px;
	}

	.money_text_item {
		width: 300px;
		height: auto;
		display: flex;
		justify-content: space-between;
	}
	
	.money_text_item img{
		width: 100%;
		height: auto;
	}

	.money_text_item_active {
		background-color: #fdc35f;
	}

	.money_text_box {
		font-size: 14px;
		color: #FFFFFF;
		padding-left: 16px;
		padding-top: 10px;
	}

	.money_money_box {
		width: 100px;
		height: 64px;
		line-height: 64px;
		box-sizing: border-box;
		background-color: #FFFFFF;
		position: relative;
		border-radius: 10px;
		color: #53d4cf;
	}

	.year-num {
		font-size: 20px;
	}

	.money-text {}



	.money-num {
		font-size: 22px;
		font-weight: bold;
		/* text-align: center; */
		padding-left: 8px;
	}

	.money-unit {
		position: absolute;
		top: -2px;
		right: 8px;
		font-size: 14px;
	}

	.money_text_item_active .money_money_box {
		background-color: #ffd95f;
		color: #FFFFFF;
	}


	.tabe_box {
		width: 800px;
		height: 630px;
		background-color: #ffffff;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}

	.tabe_box_img {
		width: auto;
		height: 630px;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.table_header {
		width: 592px;
		height: 27px;
		background-color: #515474;
		padding: 20px 50px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		display: flex;
		color: #FFFFFF;
		font-size: 22px;
	}

	.table_header div {
		display: flex;
	}
	
	.table_header div img {
		margin-right: 10px;
	}
	
	.title_username{
		width: 100px;
	}
	.title_education{
		width: 100px;
		margin-left:24px;
	}
	.title_salary{
		width: 130px;
		margin-left: 50px;
	}
	.title_company{
		width: 130px;
		margin-left: 54px;
	}

	.table_body {
		width: 692px;
		height: 500px;
		background-color: #FFFFFF;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
	}

	.good_news_slide {
		width: 100%;
		height: 50px;
		line-height: 50px;
		display: flex;
		flex-wrap: wrap;
		font-size: 16px;
		padding-left: 40px;
		box-sizing: border-box;
	}

	.offer_username {
		width: 100px;
		height: auto;
	}

	.offer_education {
		width: 100px;
		height: auto;
		margin-left: 38px;
	}

	.offer_salary {
		width: 100px;
		height: auto;
		margin-left: 70px;
	}

	.offer_company {
		width: 190px;
		height: auto;
		margin-left: 38px;
	}
</style>
